import vueFormGenerator from 'vue-form-generator';
import validator from './object.validators';

export const model = {
	ObjectId: 0,
	UserId: null,
	ObjectNameSk: null,
	ObjectNameCz: null,
	ObjectUrlName: null,
	ObjectUrlLink: null,
	VerifiedByUserId: null,
	ObjectIsVerified: null,
	AccommodationUnitsAllowed: null,
	ObjectReviewsEnabled: null,
	ObjectRegistrationDateEnabled: null,
	ObjectIsSectionOurtip: null,
	ObjectCondition: null,
	ObjectInternalNote: null,
	ObjectVipPlacings: null,
	ObjectVipPlacingsDateValidStart: null,
	ObjectVipPlacingsDateValidEnd: null,
	ObjectCountryId: null,
	DateRange: {
		startDate: null,
		endDate: null,
	},
	EmailExists: false,
	InvoiceAddressId: 0,
};

export const fields = [
	{
		fields: [
			{
				type: 'input',
				inputType: 'text',
				model: 'ObjectUrlName',
				required: true,
				validator: validator.validateObjectUrl,
				i18n: {
					label: 'urlname',
				},
			},
			{
				type: 'verified-switch',
				model: 'ObjectIsVerified',
				user: null,
				date: null,
				i18n: {
					label: 'completedpresen',
					textOn: 'yes2220',
					textOff: 'no2221',
				},
			},
			{
				type: 'switch',
				model: 'AccommodationUnitsAllowed',
				readonly: false,
				featured: false,
				disabled: false,
				default: true,
				i18n: {
					label: 'accommodationun',
					textOn: 'yes2220',
					textOff: 'no2221',
				},
			},
			{
				type: 'switch',
				model: 'ObjectReviewsEnabled',
				readonly: false,
				featured: false,
				disabled: false,
				default: true,
				i18n: {
					label: 'ratingson',
					textOn: 'yes2220',
					textOff: 'no2221',
				},
			},
			{
				type: 'switch',
				model: 'ObjectRegistrationDateEnabled',
				readonly: false,
				featured: false,
				disabled: false,
				default: true,
				i18n: {
					label: 'wecooperatefrom',
					textOn: 'yes2220',
					textOff: 'no2221',
				},
			},
			{
				type: 'switch',
				model: 'ObjectIsSectionOurtip',
				readonly: false,
				featured: false,
				disabled: false,
				default: true,
				i18n: {
					label: 'backlinkscooper',
					textOn: 'yes2220',
					textOff: 'no2221',
				},
			},
			{
				type: 'select',
				model: 'ObjectCondition',
				validator: vueFormGenerator.validators.integer,
				values: [],
				selectOptions: {},
				i18n: {
					label: 'statusofobject',
					noneSelectedText: 'select',
				},
			},
			{
				type: 'textArea',
				rows: 8,
				model: 'ObjectInternalNote',
				min: 1,
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'remark:',
				},
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'ObjectVipPlacings',
				validator: vueFormGenerator.validators.number,
				label: '',
				i18n: {
					label: 'vipposition'
				},
				styleClasses: 'quarter-width',
			},
			{
				type: 'single-datepicker',
				language: 'sk',
				model: 'ObjectVipPlacingsDateValidStart',
				validator: vueFormGenerator.validators.date,
				required: false,
				autoselect: true,
				showtime: false,
				i18n: {
					label: 'displayedinthet',
				},
			},
			{
				type: 'single-datepicker',
				language: 'sk',
				model: 'ObjectVipPlacingsDateValidEnd',
				validator: vueFormGenerator.validators.date,
				required: false,
				autoselect: true,
				showtime: false,
				i18n: {
					label: 'vipvalidto',
				},
			},
			{
				type: 'invoice-address',
				showQuestion: true,
				model: 'InvoiceAddressId',
				i18n: {
					label: 'billingaddress',
				},
			},
		],
	},
];
